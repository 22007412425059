import React, { useState, useEffect } from "react";
import connectWallet from "../../page/connect";
import logo from "../../asset/img/logo.png";
import Web3 from "web3";
import { Link } from "react-router-dom";
import "./index.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Header() {
  const [walletAddress, setWalletAddress] = useState(null);

  const handleConnectButtonClick = async () => {
    const address = await connectWallet();

    if (address) {
      setWalletAddress(address);
    } else {
      console.log("Failed to connect to wallet.");
    }
  };
  const handleDisconnectButtonClick = () => {
    setWalletAddress(null);
    // alert("Disconnect ");
  };

  const coppyAddressWallet = () => {
    navigator.clipboard.writeText(walletAddress);
    toast.success("Copy WalletAddress!", {
      autoClose: 1000,
    });
  };

  const handleHover = (item) => {
    if (item.state === 1) {
      // Do something when hovering
      console.log("Coming Soon");
    }
  };

  const handleClickonTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div>
      <nav class="navbar fixed-top">
        <div class="container nav3">
          <a class="navbar-brand" href="https://www.solanagame.co/">
            <img src={logo} alt="" width={150} style={{ cursor: "pointer" }} />
          </a>

          {!walletAddress && (
            <center>
              <button
                onClick={handleConnectButtonClick}
                class="codepro-custom-btn codepro-btn-6"
                target="blank"
                title="Code Pro"
                onclick="window.open('https://www.code.pro.vn/')"
              >
                <span>Connect</span>
              </button>
            </center>
          )}
          {walletAddress && (
            <div>
              <div class="btn-group">
                <button
                  type="button"
                  class="btn btn-disconnect dropdown-toggle"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {walletAddress.length > 4
                    ? `${walletAddress.substring(0, 4)}...` +
                      `${walletAddress.substring(5, 8)}`
                    : walletAddress}
                </button>
                <ul class="dropdown-menu dropdown-menu-end btn-disconnect-dropdown">
                  <li>
                    <a
                      class="dropdown-item"
                      href="#"
                      onClick={handleDisconnectButtonClick}
                    >
                      Disconnect
                    </a>
                  </li>
                  <li>
                    <a
                      class="dropdown-item"
                      href="#"
                      onClick={coppyAddressWallet}
                    >
                      Coppy Address Wallet
                    </a>
                  </li>
                  {/* <li>
                      <a class="dropdown-item" href="#">
                        Something else here
                      </a>
                    </li> */}
                </ul>
              </div>
            </div>
          )}
        </div>
        <ToastContainer />
      </nav>{" "}
    </div>
  );
}

export default Header;
