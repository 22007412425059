import './App.css';
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Games from './page/games';
import FakeDataComponent from './page/test';
function App() {
  return (
      <BrowserRouter>
      <Routes>
          <Route path="/" element={<Games />} />
          <Route path="/game" element={<Games />} />
          <Route path="/test" element={<FakeDataComponent />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
