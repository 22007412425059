import React, { useState, useEffect } from 'react';
import './index.css';

const Parallax = ({ children, speed = 0.5 }) => {
  const [offsetY, setOffsetY] = useState(0);
  const [isStopped, setIsStopped] = useState(false);

  const handleScroll = () => {
    setOffsetY(window.pageYOffset);
    if (window.pageYOffset >= document.body.scrollHeight - window.innerHeight) {
      setIsStopped(true);
    } else {
      setIsStopped(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    document.body.style.backgroundColor = '#1A1A1A';

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleTransitionEnd = () => {
    if (isStopped) {
      setOffsetY(window.pageYOffset);
      document.body.style.backgroundColor = '#1A1A1A'; 
    }
  };

  return (
    <div
      style={{
        transform: isStopped ? `none` : `translateY(${offsetY * speed }px)`,
        transition: isStopped ? 'transform 2s ease-out' : 'none'
      }}
      onTransitionEnd={handleTransitionEnd}
      className='transition'
    >
      {children}
    </div>
  );
};

export default Parallax;
