import React, { useState, useEffect } from "react";
import "./index.css";
import Parallax from "../home";
import connectWallet from "../connect";
import Web3 from "web3";
import { Link } from "react-router-dom";
import backgroundImage from "../../asset/img/gaming.jpg";
import logo from "../../asset/img/logo.png";
import img1 from "../../asset/img/img1.png";
import img2 from "../../asset/img/img2.png";
import img3 from "../../asset/img/img3.png";
import img4 from "../../asset/img/img4.png";
import img5 from "../../asset/img/img5.png";
import img6 from "../../asset/img/img6.png";
import img7 from "../../asset/img/img7.png";
import img8 from "../../asset/img/img8.png";
import img9 from "../../asset/img/img9.png";
import img10 from "../../asset/img/img10.png";
import img11 from "../../asset/img/img11.png";
import img12 from "../../asset/img/img12.png";
import img13 from "../../asset/img/img13.png";
import img14 from "../../asset/img/img14.png";
import img15 from "../../asset/img/img15.png";
import img16 from "../../asset/img/img16.png";
import img17 from "../../asset/img/img17.png";
import img18 from "../../asset/img/img18.png";
import img19 from "../../asset/img/img19.png";
import img20 from "../../asset/img/img20.png";
import img21 from "../../asset/img/kadick.gif";
import iconOntop from "../../asset/img/icon-to-top-af759ff9.svg";

import Header from "../../components/Header";
// import Footer from "../../components/Footer";

import icon from "../../asset/img/iconconnect.png";
const Data = [
  { id: 19, img: img19, name: "Titi Coin", state: 2 },
  { id: 20, img: img21, name: "Solgoku", state: 2 },
  { id: 3, img: img3, name: "Forza Horizon 4", state: 1 },
  { id: 4, img: img4, name: "Destiny 2", state: 1 },
  { id: 5, img: img5, name: "Knock out city", state: 1 },
  { id: 6, img: img6, name: "Fall Guys", state: 1 },
  { id: 7, img: img7, name: "NARAKA", state: 1 },
  { id: 8, img: img8, name: "Battlefield V", state: 1 },
  { id: 9, img: img9, name: "Apex Legends", state: 1 },
  { id: 10, img: img10, name: "ARK: Survival Evolved", state: 1 },
  { id: 11, img: img11, name: "Counter-Strike 2", state: 1 },
  { id: 12, img: img12, name: "Dead by Daylight", state: 1 },
  { id: 13, img: img13, name: "Dota 2", state: 1 },
  { id: 1, img: img1, name: "Don’t Starve", state: 1 },
  { id: 15, img: img15, name: "Gang Beasts", state: 1 },
  { id: 2, img: img2, name: "Don’t Starve Together", state: 1 },
  { id: 17, img: img17, name: "Madden NFL 21", state: 1 },
  { id: 18, img: img18, name: "Marvel vs. Capcon: Infinite", state: 1 },
];

function Games() {
  const handleClickonTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div>
      <div className="">
        <Header />
      </div>
      <div className="main-content ">
        <div class="parallax ">
          <Parallax speed={0.3}>
            <img
              src={backgroundImage}
              alt="Parallax Image"
              style={{
                width: "100%",
                height: "auto",
                filter: "brightness(60%)",
              }}
            />
          </Parallax>
        </div>
        <div className="container-fluid">
          <div className="game-title-play">
            <div className="d-flex justify-content-evenly container-fluid custom-text-play my-5 py-5">
              <div className="text-play-box">
                <p className="text-play-p">PLAY AS SOLO</p>
                <p className="text-play-p-2">
                  Join the many players and have fun with the endless selection
                  of games we have to offer
                </p>
              </div>
              <div className="text-play-box">
                <p className="text-play-p">PLAY WITH PARTY</p>
                <p className="text-play-p-2">
                  Get your friends and hop in the amazing duo rooms or team
                  rooms made with the best hardware
                </p>
              </div>
              <div className="text-play-box">
                <p className="text-play-p">PLAY IN TOURNAMENTS</p>
                <p className="text-play-p-2">
                  Prove that you are the very best and raise above all in our
                  weekly and seasonal tournaments climbing the leaderboard
                </p>
              </div>
            </div>
            {/* <div className=" d-flex gap-3 container my-5">
              <input
                class="form-control mr-2 input-search"
                type="search"
                placeholder="Search"
                aria-label="Search"
              />
              <button class="btn my-2 my-sm-0 bg-light" type="submit">
                Search
              </button>
            </div> */}

            <div className="container-fluid">
              <div className="row game-list-img">
                {Data.map((item) => (
                  <div key={item.id} className="col my-4 game-list-img-sub">
                    <div className="d-flex justify-content-center align-items-center game-img">
                      <img src={item.img} alt="" />

                      <button class="btn btn-game" type="submit">
                        Claim
                      </button>
                      {item.state === 1 && (
                        <p className="coming-soon">COMING SOON</p>
                      )}
                    </div>
                    <p className="text-center game-list-text-sub">
                      {item.name}
                    </p>
                  </div>
                ))}
              </div>
           
            </div>

            <div className=""></div>
          </div>
          <div className="custom_btn_iconscrol">
                <div className="btn_iconscrol" onClick={handleClickonTop}>
                  <img className="" src={iconOntop} alt="" />
                </div>
              </div>
        </div>
      </div>
    </div>
  );
}

export default Games;
