import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const connectWallet = async () => {
  if (window.solana && window.solana.isPhantom) {
    try {
      if (!window.solana.isConnected) {
        await window.solana.connect();
      }

      const fromPublicKey = window.solana.publicKey.toString();
      toast.success("Connect Wallet Success !", {
        autoClose: 1000,
      });
     
      return fromPublicKey;
    } catch (error) {
      toast.error("Install Wallet Phantom !", {
        autoClose: 1000,
      });
   
      return null;
    }
  } else {
    toast.error("Install Wallet Phantom !", {
      autoClose: 1000,
    });
    return null;
  }
};
export default connectWallet;